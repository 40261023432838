<template>
  <div>
    <slot>
      <el-button type="text" @click="show" class="changeBtn">切换地址</el-button>
    </slot>
    <el-dialog title="选择收货地址" :visible.sync="dialogTableVisible">
      <el-table
        :data="list"
        element-loading-text="加载中"
        fit
        highlight-current-row
        ref="multipleTable"
        tooltip-effect="dark"
        v-loading.body="listLoading"
        empty-text="您还未添加收货地址"
      >
        <el-table-column align="center" label="收货人" prop="consignee">
          <template slot-scope="scope">
            <span>{{ scope.row.consignee }}-{{ scope.row.mobile }}</span>
          </template>
        </el-table-column>
        <el-table-column align="center" label="收货地址" prop="address">
          <template slot-scope="scope">
            <span class="default" v-if="scope.row.isDefault === '1'">默认</span>
            <span
              >{{ scope.row.provinceName }}{{ scope.row.cityName }}{{ scope.row.zoneName
              }}{{ scope.row.addressDetail }}</span
            >
          </template>
        </el-table-column>
        <el-table-column align="center" label="操作">
          <template slot-scope="scope">
            <el-button @click="select(scope.row)" type="text">选择</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination">
        <el-pagination
          :current-page="pageNo"
          :disabled="listLoading"
          :page-size="pageSize"
          :page-sizes="[10, 20, 30, 40]"
          :total="total"
          @current-change="handleCurrentChange"
          @size-change="handleSizeChange"
          background
          layout="total, sizes, prev, pager, next, jumper"
        ></el-pagination>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { list } from '@/api/address/address';
import pick from 'lodash/pick';
import pickBy from 'lodash/pickBy';
import cloneDeep from 'lodash/cloneDeep';
import AddressManagement from '@/components/AddressManagement';
export default {
  data() {
    const initFilter = {
      name: '', // 参数名称
    };
    return {
      dialogTableVisible: false, // 是否显示弹窗
      initFilter,
      filter: cloneDeep(initFilter),
      list: [],
      listLoading: true,
      pageNo: 1,
      pageSize: 10,
      orderBy: 'create_date',
      total: 0,
      multipleSelection: [],
      ids: [],
    };
  },
  components: {
    AddressManagement,
  },
  computed: {
    // 过滤
    data() {
      return pickBy(this.filter, (val) => !!val);
    },
  },
  methods: {
    // 显示地址弹窗
    show() {
      this.fetchData();
      this.dialogTableVisible = true;
    },
    // 选择地址
    select(row) {
      this.dialogTableVisible = false;
      this.$emit('selectAddress', row);
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.pageNo = 1;
      this.fetchData();
    },
    handleCurrentChange(val) {
      this.pageNo = val;
      this.fetchData();
    },
    fetchData() {
      this.listLoading = true;
      const listQuery = pick(this, ['pageNo', 'pageSize', 'orderBy', 'data']);
      list(listQuery)
        .then((response) => {
          this.list = response.data.list;
          this.total = response.data.total;
        })
        .finally(() => {
          this.listLoading = false;
        });
    },
  },
};
</script>
<style rel="stylesheet/scss" lang="scss" scoped>
@import './ChooseAddress';
</style>
