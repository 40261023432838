<template>
  <div class="membercard-wrap">
    <slot> 实名认证 </slot>
    <div v-if="data">
      <span class="name">{{ data.name }} </span><span class="id-card">{{ data.idcardNo }}</span>
      <MemberManagement @sureRefresh="sureRefresh" :id="'edit'"></MemberManagement>
    </div>
    <MemberManagement v-else @sureRefresh="sureRefresh" ref="add">
      <div class="btn-member" @click="$refs.add.show()">+填写实名认证信息（海关使用）</div>
    </MemberManagement>
  </div>
</template>
<script>
import MemberManagement from '@/components/views/checkout/MemberManagement';
import { get } from '@/api/memberIdcard';
export default {
  name: 'MemberIdcard',
  components: {
    MemberManagement,
  },
  data() {
    return {
      data: {},
    };
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    // 实名认证操作-新增/编辑-成功之后刷新数据
    sureRefresh() {
      console.log('sureRefresh');
      this.fetchData();
    },
    // 获取实名认证信息，如果有则展示，没有则隐藏
    fetchData() {
      this.loading = true;
      return get()
        .then((response) => {
          this.data = response.data;
          if (this.data) {
            this.$emit('menberid', true);
            this.$emit('saveIdcard', { buyerName: this.data.name, idcardNo: this.data.idcardNo });
          } else {
            this.$emit('menberid', false);
            this.$emit('saveIdcard', {});
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
p {
  padding: 0;
  margin: 0;
  color: #666;
}
.id-card {
  margin: 0 50px 0 10px;
}
.membercard-wrap {
  margin-bottom: 15px;
}
.btn-member {
  width: 480px;
  height: 50px;
  line-height: 50px;
  color: #666;
  border: 1px dashed #ccc;
  border-radius: 5px;
  text-align: center;
  padding: 0 10px;
  cursor: pointer;
}
</style>
