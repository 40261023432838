<template>
  <div class="cart">
    <div class="cart__table">
      <el-table :data="goodsAndGiftList" style="width: 100%">
        <el-table-column label="商品信息" width="338px" header-align="center">
          <template slot-scope="scope">
            <div class="table__name">
              <img :src="scope.row.imgUrl" class="table__name--img" />
              <p class="table__name--name">{{ scope.row.commodityName }}</p>
              <span class="table__name--rebate" v-if="scope.row.creditBack === '1'">返利</span>
              <span
                class="table__name--rebate table__name--gift"
                v-if="scope.row.goodType === 'gift'"
                >赠品</span
              >
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="name" label="规格" width="auto" align="center">
          <template slot-scope="scope">
            <p>
              {{ scope.row.firstLevel ? scope.row.firstLevel : ''
              }}{{ scope.row.secondLevel | judge }}{{ scope.row.threeLevel | judge }}
            </p>
          </template>
        </el-table-column>
        <el-table-column prop="specCode" label="规格标识" width="auto" align="center">
        </el-table-column>
        <el-table-column label="单价" width="auto" align="center">
          <template slot-scope="scope">
            <span class="table__name--price">{{ scope.row.price }}</span>
          </template>
        </el-table-column>
        <el-table-column label="购买数量" width="auto" align="center">
          <template slot-scope="scope">
            <span class="table__name--price">{{ scope.row.quantity }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="allPrice" label="小计" width="210px" align="center">
          <template slot-scope="scope">
            <span class="table__name--allPrice">{{
              $route.query.goodsType === 'sample' ? scope.row.price : scope.row.allPrice
            }}</span>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="cart__info">
      <p class="cart__info--title" v-if="businessntity">{{ brandType === 'INTERNATION' ? '合作' : '自有' }}仓商品：经营主体【{{ businessntity }}】</p>
      <p class="cart__info--content">
        <label class="cart__label">合同主体：</label>
        <el-tooltip class="item" effect="dark" placement="top-start" popper-class="store__account__tip">
          <div slot="content">合同主体主要用于订单归属合同，<br/>并且用于后续订单开票</div>
          <i class="el-icon-warning-outline cart__info--icon"></i>
        </el-tooltip>
        <template v-if="contractList.length">
          <el-select v-model="distributorContractInfoId" :placeholder="`有${contractList.length}个主体可选`" style="flex: 1;">
            <el-option
              v-for="item in contractList"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </template>
        <template v-else>
          <span class="cart__info--not">暂无可用合同主体，请联系专属顾问</span>
        </template>
      </p>
    </div>
    <div class="cart__content">
      <div class="cart__content--l">
        <div class="cart__content--coupon">
          <ChooseCoupon
            :couponIds="couponIds"
            :userCouponId="userCouponId"
            @selectCoupon="selectCoupon"
            v-show="couponIds.length > 0"
          ></ChooseCoupon>
        </div>
        <div class="cart__content--remark">
          <label>留言</label>
          <el-input
            placeholder="请输入内容"
            type="textarea"
            v-model="buyerNote"
            maxlength="50"
            show-word-limit
            rows="3"
          ></el-input>
        </div>
      </div>
      <div class="cart__content--r">
        <p v-if="payment.saleAmount" class="cart__content--rItem">
          共<span class="red">{{ goodsQuantity }}</span
          >件商品 总价：
          <span class="cart__content--priceSpan">
            ¥
            <em>{{ payment.saleAmount }}</em>
          </span>
        </p>
        <p v-if="payment.deliveryFee" class="cart__content--rItem">
          运费：
          <span class="cart__content--priceSpan">
            ¥
            <em>{{ payment.deliveryFee }}</em>
          </span>
        </p>
        <p v-else class="cart__content--rItem">
          配送方式：
          <span class="cart__content--priceSpan">包邮</span>
        </p>
        <p
          :key="item.activityId"
          v-for="item in payment.marketingDiscountVOS"
          class="cart__content--rItem"
        >
          <span v-if="item.activityType !== 'GIFT_OFF'">
            <el-checkbox
              v-if="item.activityType === 'VIRTUAL_CREDIT'"
              v-model="virtualCreditChecked"
              :disabled="payment.virtualCredit === 0 || item.discountAmount === 0"
            >
              {{ item.activityTypeName }}(剩余￥{{ payment.virtualCredit }}):
              <span class="cart__content--priceSpan">
                -¥
                <em>{{ item.discountAmount }}</em>
              </span>
            </el-checkbox>
            <span v-else
              >{{ (item.couponType && item.couponType === 'DELIVERY_FEE')? '运费券':item.activityTypeName }}：
              <span class="cart__content--priceSpan">
                -¥
                <em>{{ item.discountAmount }}</em>
              </span>
            </span>
          </span>
        </p>
        <p class="red cart__content--rItem">
          订单合计金额：<span class="cart__content--priceSpan"
            >¥<em>{{ payment.payAmount }}</em></span>
        </p>
        <p class="cart__content--sample" v-if="payment.sampleOrderMarketingDiscountVO && payment.sampleOrderMarketingDiscountVO.hasUserCoupon > '0'">
          <span class="cart__content--title">{{ payment.sampleOrderMarketingDiscountVO.name }}</span>
          <span class="cart__content--priceSpan">
            <el-checkbox v-model="checked"></el-checkbox>
          </span>
        </p>
      </div>
    </div>
    <div class="cart__footer">
      <div class="cart__footer--fl">
        <router-link class="router-links" to="/syoung/car/list">
          <i class="iconfont icon-fanhui">返回采购车</i>
        </router-link>
      </div>
      <div class="cart__footer--fr">
        <div class="cart__footer--price">
          共<span class="red">{{ goodsQuantity }}</span
          >件商品,应付总额：
          <span class="red">¥{{ payment.payAmount }}</span>
        </div>
        <el-button
          :loading="loading"
          :disabled="payment.distributorType !== 'DISTRIBUTE' && orderType === 'global'"
          @click="createOrder"
          type="primary"
          class="cart__footer--btn"
          >提交订单</el-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import ChooseCoupon from '@/components/views/checkout/ChooseCoupon';
import { mapActions } from 'vuex';
import { create, getSignUrl, createSampleApply } from '@/api/checkout';
import { recommendOrderCreate, getOrderContractInfo } from '@/api/recommended-list/index';
import { getAuthenticateInfo } from '@/api/step';
export default {
  name: 'CheckoutGoods',
  components: {
    ChooseCoupon,
  },
  props: {
    goodsList: {
      type: Array,
      default() {
        return [];
      },
    },
    payment: {},
    checkoutPriceData: {},
    menberidVal: {
      type: Boolean,
    },
  },
  mounted() {
    this.userTypeInfo();
    this.getContractInfo();
  },
  data() {
    return {
      checked: true,
      goodsType: this.$route.query.goodsType || 'ordinary', // 下单商品种类  小样 sample   普通 ordinary    清单详情  recommended
      brandType: this.$route.query.brandType, // 品牌类型
      buyerNote: '', // 买家备注
      authencationType: 'DISTRIBUTE', // 用户类型，默认采销
      orderType: this.$route.query.type, // 订单类型
      balanceChecked: false, // 是否余额支付
      virtualCreditChecked: true, // 是否使用返利
      goodsQuantity: 0, // 商品总件数
      loading: false, // 按钮loading
      isCouponPrompt: false, // 是否弹窗优惠券使用提示
      modalContent: '', // 提示框文案
      modalTitle: '', // 提示框标题
      contractList: [], // 合同主体列表
      businessntity: '', // 经营主体
      distributorContractInfoId: '',
      ownCompanyId: '',
    };
  },
  watch: {
    'payment.virtualCredit': {
      handler: function (val) {
        if (val === 0) {
          this.virtualCreditChecked = false;
        }
      },
      immediate: true,
      deep: true,
    },
    'payment.marketingDiscountVOS': {
      handler: function (val) {
        if (val) {
          const rebate = val.find(i => i.activityType === 'VIRTUAL_CREDIT');
          if (!rebate || rebate.discountAmount <= 0) {
            this.virtualCreditChecked = false;
          }
        }
      },
      immediate: true,
      deep: true,
    },
    virtualCreditChecked(val) {
      this.$emit('virtualCreditChange', val);
    },
  },
  computed: {
    goodsAndGiftList() {
      let giftList = [];
      //  如果存在赠品，获取赠品列表
      if (this.payment.marketingDiscountVOS && this.payment.marketingDiscountVOS.length > 0) {
        this.payment.marketingDiscountVOS.forEach((item) => {
          if (item.activityType === 'GIFT_OFF') {
            giftList = item.giftDetailVOS;
          }
        });
      }
      // 赠品数据格式转化成商品的格式
      const giftGoodsList = giftList.map((item) => {
        return {
          commodityName: item.name,
          price: 0,
          quantity: item.quantity,
          allPrice: 0,
          imgUrl: item.thumbnailUrl,
          goodType: 'gift',
          firstLevel: item.firstLevel,
          secondLevel: item.secondLevel,
          threeLevel: item.threeLevel,
          specCode: item.specCode,
        };
      });
      this.goodsQuantity = 0;
      this.goodsList.concat(giftGoodsList).forEach((item) => {
        this.goodsQuantity += item.quantity;
      });
      if (this.$route.query.goodsType === 'sample') {
        const { payAmount, specCode, firstLevel, secondLevel, threeLevel } = this.payment;
        this.goodsList[0].allPrice = payAmount;
        this.goodsList[0].specCode = specCode;
        this.goodsList[0].firstLevel = firstLevel;
        this.goodsList[0].secondLevel = secondLevel;
        this.goodsList[0].threeLevel = threeLevel;
      }
      return this.goodsList.concat(giftGoodsList);
    },
    // 优惠列表
    couponIds() {
      let couponIds = [];
      if (this.payment.marketingDiscountVOS && this.payment.marketingDiscountVOS.length > 0) {
        this.payment.marketingDiscountVOS.forEach((item) => {
          if (item.activityType === 'COUPON') {
            couponIds = item.couponIds;
            if (item.couponAmount > item.discountAmount) {
              this.isCouponPrompt = true; // 是否启用弹窗优惠券使用提示
              this.modalTitle = '优惠券使用提醒';
              if (item.couponType && item.couponType === 'DELIVERY_FEE') {
                this.modalContent = '您的运费券额度没有使用完，你确定要使用该券吗，支付成功后不会退还';
              }else {
                this.modalContent = `您的无门槛优惠券【减${
                  item.couponAmount
                }】的额度没有使用完，您还可以购买${
                  item.couponAmount - item.discountAmount
                }元商品哦。如果您确定不再选购，那么支付成功后优惠券不会退还的哦！`;
              }
            } else {
              this.isCouponPrompt = false;
            }
          }
        });
      }
      return couponIds;
    },
    // 当前使用的优惠券id
    userCouponId() {
      let userCouponId = '';
      if (this.payment.marketingDiscountVOS && this.payment.marketingDiscountVOS.length > 0) {
        this.payment.marketingDiscountVOS.forEach((item) => {
          if (item.activityType === 'COUPON') {
            userCouponId = item.userCouponId;
          }
        });
      }
      return userCouponId;
    },
    // 是否能余额支付
    balanceCheckFlag() {
      if (
        this.payment.payAmount <= this.payment.balanceAmount &&
        this.$route.query.type === 'normal' &&
        this.authencationType === 'DISTRIBUTE'
      ) {
        this.balanceChecked = true;
        return false;
      } else {
        this.balanceChecked = false;
        return true;
      }
    },
  },
  methods: {
    // vuex 采购车商品数量
    ...mapActions(['getCountStatisticsHot']),
    userTypeInfo() {
      getAuthenticateInfo().then((res) => {
        this.authencationType = res.data.type;
      });
    },
    selectCoupon(value) {
      this.$emit('coupongChange', value);
    },
    // 创建订单
    createOrder() {
      if (this.$route.query.goodsType && this.$route.query.goodsType === 'sample') {
        // 是小样支付
        this.createSampleApply();
        return;
      }
      if (
        !this.menberidVal &&
        (this.$route.query.type === 'global' || this.$route.query.type === 'GLOBAL')
      ) {
        this.$message({
          message: '请填写实名信息',
          type: 'warning',
        });
        return;
      }
      if (!this.checkoutPriceData.consigneeAddressId) {
        this.$message({
          message: '请填写地址',
          type: 'warning',
        });
        return;
      }
      // if (!this.contractList.length) {
      //   this.$message({
      //     message: '暂无可用合同主体，请联系专属顾问',
      //     type: 'warning',
      //   });
      //   return;
      // }
      if (!this.distributorContractInfoId && this.contractList.length) {
        this.$message({
          message: '请选择合同主体',
          type: 'warning',
        });
        return;
      }
      if (this.isCouponPrompt) {
        // 是否要弹窗提示 优惠券额度
        this.$confirm(this.modalContent, this.modalTitle, {
          confirmButtonText: '确定',
          showCancelButton: false,
          type: 'warning',
        })
          .then(() => {})
          .catch(() => {});
        this.isCouponPrompt = false;
        return;
      }
      this.checkoutPriceData.buyerNote = this.buyerNote;
      this.checkoutPriceData.isUseVirtualCredit = this.virtualCreditChecked ? '1' : '0';
      this.checkoutPriceData.isUsePassword = '1';
      this.checkoutPriceData.distributorContractInfoId = this.distributorContractInfoId;
      this.loading = true;
      this.createRequest();
    },
    createRequest() {
      const repObjFn = {
        interface: {
          create: create, // 普通结算接口
          recommendOrderCreate: recommendOrderCreate, // this.goodsType === 'recommended'时  清单详情接口
        },
        get(type) {
          switch (type) {
            case 'recommended':
              return this.interface.recommendOrderCreate;
            default:
              return this.interface.create;
          }
        },
      };
      repObjFn
        .get(this.goodsType)(this.checkoutPriceData)
        .then((res) => {
          // 需完善资质
          if (res.code === 'DISTRIBUTOR_CODE_501') {
            this.$confirm(res.msg, '温馨提示', {
              confirmButtonText: '去完善',
              cancelButtonText: '取消',
              type: 'warning',
            }).then(() => {
              this.$router.replace('/syoung/register/step?supplementStatus=NO');
            });
            return;
          }
          // 资质待审核
          if(res.code === 'DISTRIBUTOR_CODE_502'){
            this.$router.replace('/syoung/register/step?supplementStatus=WAIT');
            return;
          }
          this.getCountStatisticsHot();
          if (res.code === '0' && res.data.payAmount > 0) {
            const { bizType, id, payAmount, expireTime } = res.data;
            this.postData = {
              bizType,
              id,
              payAmount,
              expireTime,
              customerTel: res.data.buyerMobile,
              customerIdNo: res.data.orderGlobal?.idcardNo || '',
              customerName: res.data.orderGlobal?.buyerName || '',
              goodsCount: this.getGoodsCount(res.data.orderDetailVOList),
              ownCompanyId: this.ownCompanyId,
              brandType: this.brandType,
            };
            sessionStorage.setItem('payData', JSON.stringify(this.postData));
            this.$router.push(`/syoung/pay/list?type=${this.$route.query.type}`);
            return;
          }
          if (res.code === 'UNSIGN001') {
            this.$confirm('您还未签署合同，请立即签署合同', '提示', {
              confirmButtonText: '立即签署',
              cancelButtonText: '取消',
              type: 'warning',
            })
              .then(() => {
                getSignUrl().then((res) => {
                  window.open(res.data);
                });
              })
              .catch(() => {});
            return;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    createSampleApply() {
      // 小样支付
      if (
        !this.menberidVal &&
        (this.$route.query.type === 'global' || this.$route.query.type === 'GLOBAL')
      ) {
        this.$message({
          message: '请填写实名信息',
          type: 'warning',
        });
        return;
      }
      if (!this.checkoutPriceData.addressId) {
        this.$message({
          message: '请填写地址',
          type: 'warning',
        });
        return;
      }
      // if (!this.contractList.length) {
      //   this.$message({
      //     message: '暂无可用合同主体，请联系专属顾问',
      //     type: 'warning',
      //   });
      //   return;
      // }
      if (!this.distributorContractInfoId && this.contractList.length) {
        this.$message({
          message: '请选择合同主体',
          type: 'warning',
        });
        return;
      }
      this.loading = true;
      this.checkoutPriceData.distributorContractInfoId = this.distributorContractInfoId;
      const userCouponId = this.checked ? (this.payment?.sampleOrderMarketingDiscountVO?.userCouponId || '') : '';
      if (userCouponId) {
        this.checkoutPriceData.userCouponId = userCouponId;
      }
      createSampleApply(this.checkoutPriceData)
        .then((res) => {
          // 需完善资质
          if (res.code === 'DISTRIBUTOR_CODE_501') {
            this.$confirm(res.msg, '温馨提示', {
              confirmButtonText: '去完善',
              cancelButtonText: '取消',
              type: 'warning',
            }).then(() => {
              this.$router.replace('/syoung/register/step?supplementStatus=NO');
            });
            return;
          }
          // 资质待审核
          if(res.code === 'DISTRIBUTOR_CODE_502'){
            this.$router.replace('/syoung/register/step?supplementStatus=WAIT');
            return;
          }
          // success
          if (res.code === '0') {
            const { bizType, id, price, expireTime } = res.data;
            if (price === 0) {
              this.$router.push('/syoung/sample-receipt-record/list');
              return;
            }
            this.postData = {
              bizType,
              id,
              payAmount: price,
              expireTime,
              customerTel: res.data.buyerMobile,
              customerIdNo: res.data.orderGlobal ? res.data.orderGlobal.idcardNo : '',
              customerName: res.data.orderGlobal ? res.data.orderGlobal.buyerName : '',
              goodsCount: 1,
              ownCompanyId: this.ownCompanyId || '',
              brandType: this.brandType || '',
            };
            if (this.$route.query.type === 'global' || this.$route.query.type === 'GLOBAL') {
              this.postData.customerTel = this.checkoutPriceData.customerTel;
            }
            sessionStorage.setItem('payData', JSON.stringify(this.postData));
            this.$router.push(`/syoung/pay/list?type=${this.$route.query.type}&goodsType=sample`);

            return;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getGoodsCount(list) {
      let num = 0;
      list.forEach((item) => {
        num = item.quantity + num;
      });
      return num;
    },
    getContractInfo() {
      getOrderContractInfo({
        orderBrandType: this.brandType,
      }).then(res => {
        if(res.code === '0') {
          this.businessntity = res.data.ownCompanyName;
          this.ownCompanyId = res.data?.appId || '';
          this.contractList = res.data.contractInfoList?.map(({ id, merchantTypeName, contractInfoName }) => ({
            value: id,
            label: merchantTypeName + '-' + contractInfoName,
          })) || [];
          if(this.contractList.length === 1) {
            const [item] = this.contractList;
            this.distributorContractInfoId = item.value;
          }
        }
      });
    }
  },
  filters: {
    judge(val) {
      if (val) {
        return ` / ${val}`;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import './CheckoutGoods';
</style>
