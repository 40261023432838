import { render, staticRenderFns } from "./ChooseCoupon.vue?vue&type=template&id=08d0ada4&scoped=true"
import script from "./ChooseCoupon.vue?vue&type=script&lang=js"
export * from "./ChooseCoupon.vue?vue&type=script&lang=js"
import style0 from "./ChooseCoupon.vue?vue&type=style&index=0&id=08d0ada4&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/_vue-loader@15.11.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "08d0ada4",
  null
  
)

export default component.exports