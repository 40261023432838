<template>
  <div class="coupon">
    <slot
      ><label>可使用优惠券({{ couponIds.length }})</label></slot
    >
    <el-select v-model="value" filterable placeholder="请选择" :loading="loading">
      <el-option v-for="item in options" :key="item.id" :label="item.name" :value="item.id">
      </el-option>
    </el-select>
  </div>
</template>

<script>
import { couponList } from '@/api/checkout';
export default {
  name: 'ChooseCoupon',
  props: {
    couponIds: {
      type: Array,
      default() {
        return [];
      }
    },
    userCouponId: ''
  },
  data() {
    return {
      value: '',
      loading: false,
      options: []
    };
  },
  mounted() {},
  watch: {
    // 选择的优惠券改变时候
    value(value) {
      this.$emit('selectCoupon', value);
    },
    couponIds() {
      this.fetchData();
    }
  },
  methods: {
    fetchData() {
      this.loading = true;
      couponList(this.couponIds)
        .then(response => {
          this.options = response.data.enableCouponList || [];
          this.options.push({ id: '-1', name: '不使用优惠券' });
          this.value = this.userCouponId;
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
};
</script>

<style lang="scss" scoped>
.coupon {
  color: #333;
  display: flex;
  align-items: center;
  label {
    font-weight: 700;
    white-space: nowrap;
  }
  .el-select {
    margin-left: 10px;
    width: 100%;
  }
}
</style>
